export interface ISegmentAnalytics {
  page: (path: string, params?: any) => void;
  reset: () => void;
  group: () => void;
  track: (event: string, params?: any) => void;
  identify: (userId: string, user: IModelUser) => void;
}

declare let window: Window & typeof globalThis & { analytics?: ISegmentAnalytics };

export default function useSegment(): ISegmentAnalytics {
  const warn = (method: string, args: any[]): void => {
    console.warn(`[Segment]: 'analytics.${method}()' disabled since Segment tracking disabled or script does not exist`, {
      args,
    });
  };

  return (
    window.analytics || {
      page: (...args: any[]) => warn("page", args),
      reset: (...args: any[]) => warn("reset", args),
      group: (...args: any[]) => warn("group", args),
      track: (...args: any[]) => warn("track", args),
      identify: (...args: any[]) => warn("identify", args),
    }
  );
}
