import type { IDeviceInformation } from "./format";
import useRequest from "~/hooks/use-request";
import useSegment from "~/hooks/use-segment";
import useUserStore from "~/stores/user";
import { removeObjectFromArrayByKeyValue } from "~/utils/array";
import { formatDeviceModel, getCurrentDeviceInfo } from "./format";

const DeviceAPI = {
  current(): IDeviceInformation {
    return getCurrentDeviceInfo();
  },

  checkCurrent(): Promise<void> {
    const userStore = useUserStore();
    const device = this.current();
    const { request } = useRequest();
    const payload = {
      device_id: device.deviceId,
      device_name: device.deviceName,
      register_new: "no_register",
    };

    return request
      .post<IContentResponse<IResponseCurrentDeviceCheck>>("/api/user/check_current_device", { body: payload })
      .then((response) => {
        userStore.devices = response.content.devices.map(formatDeviceModel);

        if (!response.content.enabled)
          throw new Error("device_limit_exceeded");
      });
  },

  delete(deviceId: IModelDevice["id"], token?: string): Promise<void> {
    const userStore = useUserStore();
    const devices = userStore.devices;
    const { request } = useRequest();
    const payload = { deviceId };
    const segment = useSegment();

    return request
      .post<null>("/api/user/delete_device", { body: payload, withToken: token })
      .then(() => {
        removeObjectFromArrayByKeyValue(deviceId, "id", devices);
        segment.track("Delete Device", { deviceId });
      });
  },
};

export default DeviceAPI;
