interface UserData {
  username: string;
  password: string;
}
const createParams = (token: string, provider: TAuthProvider, userData?: UserData) => {
  const isCredentialsProvider = provider === "credentials";
  return {
    token,
    provider,
    ...(isCredentialsProvider && userData && { username: userData.username }),
    ...(isCredentialsProvider && userData && { password: userData.password }),
  };
};

export const onDeviceLimitError = (token?: string, provider?: TAuthProvider, userData?: UserData) => {
  if (!token || !provider)
    return;

  const params = createParams(token, provider, userData);
  navigateTo({
    path: "/auth/login/limit/",
    query: { data: stringToBase64(JSON.stringify(params)) },
  });
};
