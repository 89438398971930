<script setup lang="ts">
/* eslint-disable max-len */
export interface IconVideoProps {
  isDarkTheme?: boolean;
}
const props = withDefaults(defineProps<IconVideoProps>(), { isDarkTheme: true });
</script>

<template>
  <template v-if="props.isDarkTheme">
    <svg width="100%" height="100%" viewBox="0 0 298 168" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path fill="#171F2E" fill-rule="nonzero" d="M0 0h298v168H0z" />
        <path d="M224.99 73.458c-.016-2.484-1.827-4.552-4.128-4.62l-51.709-1.55c-3.127-.093-5.675 2.225-5.649 5.18l.014 1.637c.026 2.953 2.636 5.342 5.76 5.336l51.644-.101c2.3-.005 4.094-2.022 4.078-4.505l-.01-1.377zM215.01 112.265c-.016-2.579-2.019-4.5-4.467-4.288l-55.327 4.787c-3.363.291-6.128 3.033-6.1 6.117l.016 1.71c.028 3.083 2.823 5.254 6.182 4.854l55.302-6.56c2.446-.29 4.422-2.613 4.405-5.19l-.01-1.43zM169.498 89.262 87.93 91.51c-5.198.143-9.408 3.547-9.36 7.596l.027 2.341c.048 4.047 4.313 7.054 9.505 6.724l81.542-5.183c3.452-.22 6.185-3 6.16-6.214l-.017-1.86c-.027-3.214-2.835-5.746-6.29-5.65zM212.7 57.66c.012 1.788-1.36 3.166-3.08 3.076-1.74-.09-3.173-1.64-3.185-3.46-.013-1.822 1.399-3.197 3.138-3.074 1.722.123 3.114 1.67 3.127 3.459zM89.94 123.992c.027 2.404-2.397 4.624-5.45 4.964-3.096.344-5.66-1.37-5.688-3.832-.029-2.462 2.49-4.693 5.587-4.977 3.055-.281 5.524 1.442 5.551 3.845zM203.092 41.364c-1.493-.16-2.707.967-2.696 2.513.012 1.547 1.245 2.917 2.736 3.054 1.48.135 2.664-.994 2.654-2.517-.011-1.523-1.214-2.89-2.694-3.05zm.049 6.738c-2.123-.187-3.884-2.135-3.9-4.34-.016-2.205 1.718-3.806 3.842-3.57 2.099.233 3.8 2.175 3.816 4.333.015 2.157-1.661 3.762-3.758 3.577zM75.434 72.953l9.197.041-4.606-6.346-4.59 6.305zm11.49 1.112L73 74.048l7-9.568 6.924 9.585zM212.005 92.945l8.817-.88-3.86 8.181z" fill="#232F43" />
        <path d="M198.73 49.376c0-2.766-1.823-5.112-4.111-5.241l-90.53-5.125a5.66 5.66 0 0 0-.32-.01c-2.041-.012-3.802.956-4.887 2.481-.686.964-1.052 2.149-1.052 3.449v75.18c0 .433.011.85.096 1.25.021.1.028.198.054.295.105.388.24.758.419 1.102.76 1.464 2.143 2.498 3.825 2.817a6.095 6.095 0 0 0 1.874.068l70.978-8.447 19.543-2.34c2.288-.272 4.111-2.743 4.111-5.509v-59.97z" fill="#232F43" />
        <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v75.18c0 3.467 2.856 5.938 6.334 5.523l90.537-10.787c2.288-.273 4.124-2.734 4.124-5.5v-59.97c0-2.766-1.836-5.112-4.124-5.241z" fill="#374358" />
        <path d="M106.416 123.654c-2.359.272-4.295-1.415-4.295-3.761V45.02c0-2.347 1.936-4.154 4.295-4.03l90.536 4.76c1.558.082 2.817 1.683 2.817 3.568v60.168c0 1.885-1.26 3.565-2.817 3.745l-90.536 10.423z" fill="#2C374B" />
        <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v4.503l100.995 3.535v-3.592c0-2.766-1.836-5.112-4.124-5.241z" fill="#232F43" />
        <path d="M111.27 44.828c0 1.11-.9 1.971-2.016 1.926-1.12-.046-2.031-.99-2.031-2.11 0-1.118.911-1.98 2.031-1.924 1.115.055 2.015.999 2.015 2.108zM118.217 45.142c0 1.094-.875 1.944-1.959 1.9-1.088-.045-1.973-.975-1.973-2.078 0-1.102.885-1.952 1.973-1.899 1.084.054 1.96.984 1.96 2.077zM124.97 45.448c0 1.078-.851 1.917-1.905 1.874-1.057-.044-1.918-.96-1.918-2.047 0-1.087.86-1.926 1.918-1.874 1.054.052 1.904.968 1.904 2.047z" fill="#565F6F" />
        <path fill="#1C2535" d="m107.427 103.01 88.878-6.516V62.588l-88.878-.954z" />
        <path fill="#4B586F" d="m196.305 102.222-88.878 7.771v-3.157l88.878-7.201zM196.305 108.568l-88.878 9.169v-3.156l88.878-8.6zM158.099 56.1l-50.672-1.246v3.157l50.672.92zM191.825 56.928v2.616l4.48.081v-2.587zM145.102 93.885V67.642c0-1.684 1.313-2.79 2.443-2.067l19.545 12.514c1.181.757 1.181 3.112 0 3.944l-19.545 13.764c-1.13.796-2.443-.227-2.443-1.912z" />
      </g>
    </svg>
  </template>
  <template v-else>
    <svg width="100%" height="100%" viewBox="0 0 298 168" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path fill="#F1F2F2" fill-rule="nonzero" d="M0 0h298v168H0z" />
        <path d="M224.99 73.458c-.016-2.484-1.827-4.552-4.128-4.62l-51.709-1.55c-3.127-.093-5.675 2.225-5.649 5.18l.014 1.637c.026 2.953 2.636 5.342 5.76 5.336l51.644-.101c2.3-.005 4.094-2.022 4.078-4.505l-.01-1.377zM215.01 112.265c-.016-2.579-2.019-4.5-4.467-4.288l-55.327 4.787c-3.363.291-6.128 3.033-6.1 6.117l.016 1.71c.028 3.083 2.823 5.254 6.182 4.854l55.302-6.56c2.446-.29 4.422-2.613 4.405-5.19l-.01-1.43zM169.498 89.262 87.93 91.51c-5.198.143-9.408 3.547-9.36 7.596l.027 2.341c.048 4.047 4.313 7.054 9.505 6.724l81.542-5.183c3.452-.22 6.185-3 6.16-6.214l-.017-1.86c-.027-3.214-2.835-5.746-6.29-5.65zM212.7 57.66c.012 1.788-1.36 3.166-3.08 3.076-1.74-.09-3.173-1.64-3.185-3.46-.013-1.822 1.399-3.197 3.138-3.074 1.722.123 3.114 1.67 3.127 3.459zM89.94 123.992c.027 2.404-2.397 4.624-5.45 4.964-3.096.344-5.66-1.37-5.688-3.832-.029-2.462 2.49-4.693 5.587-4.977 3.055-.281 5.524 1.442 5.551 3.845zM203.092 41.364c-1.493-.16-2.707.967-2.696 2.514.012 1.546 1.245 2.916 2.736 3.053 1.48.135 2.664-.994 2.654-2.517-.011-1.523-1.214-2.89-2.694-3.05zm.049 6.738c-2.123-.187-3.884-2.135-3.9-4.34-.016-2.205 1.718-3.806 3.842-3.57 2.099.233 3.8 2.175 3.816 4.333.015 2.157-1.661 3.762-3.758 3.577zM75.434 72.953l9.197.041-4.606-6.346-4.59 6.305zm11.49 1.112L73 74.048l7-9.568 6.924 9.585zM212.005 92.945l8.817-.88-3.86 8.181z" fill="#DFDFDF" />
        <path d="M198.73 49.376c0-2.766-1.823-5.112-4.111-5.241l-90.53-5.125a5.66 5.66 0 0 0-.32-.01c-2.041-.012-3.802.956-4.887 2.481-.686.964-1.052 2.149-1.052 3.449v75.18c0 .433.011.85.096 1.25.021.1.028.198.054.295.105.388.24.758.419 1.102.76 1.464 2.143 2.498 3.825 2.817a6.095 6.095 0 0 0 1.874.068l70.978-8.447 19.543-2.34c2.288-.272 4.111-2.743 4.111-5.509v-59.97z" fill="#DFDFDF" />
        <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v75.18c0 3.467 2.856 5.938 6.334 5.523l90.537-10.787c2.288-.273 4.124-2.734 4.124-5.5v-59.97c0-2.766-1.836-5.112-4.124-5.241z" fill="#DFDFDF" />
        <path d="M106.416 123.654c-2.359.272-4.295-1.415-4.295-3.761V45.02c0-2.347 1.936-4.154 4.295-4.03l90.536 4.76c1.558.082 2.817 1.683 2.817 3.568v60.168c0 1.885-1.26 3.565-2.817 3.745l-90.536 10.423z" fill="#EEE" />
        <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v4.503l100.995 3.535v-3.592c0-2.766-1.836-5.112-4.124-5.241z" fill="#DFDFDF" />
        <path d="M111.27 44.828c0 1.11-.9 1.971-2.016 1.926-1.12-.046-2.031-.99-2.031-2.11 0-1.118.911-1.98 2.031-1.924 1.115.055 2.015.999 2.015 2.108zM118.217 45.142c0 1.094-.875 1.944-1.959 1.9-1.088-.045-1.973-.975-1.973-2.078 0-1.102.885-1.952 1.973-1.899 1.084.054 1.96.984 1.96 2.077zM124.97 45.448c0 1.078-.851 1.917-1.905 1.874-1.057-.044-1.918-.96-1.918-2.047 0-1.087.86-1.926 1.918-1.874 1.054.052 1.904.968 1.904 2.047z" fill="#EEE" />
        <path fill="#F8F8F8" d="m107.427 103.01 88.878-6.516V62.588l-88.878-.954z" />
        <path fill="#DFDFDF" d="m196.305 102.222-88.878 7.771v-3.157l88.878-7.201zM196.305 108.568l-88.878 9.169v-3.156l88.878-8.6zM158.099 56.1l-50.672-1.246v3.157l50.672.92zM191.825 56.928v2.616l4.48.081v-2.587zM145.102 93.885V67.642c0-1.684 1.313-2.79 2.443-2.067l19.545 12.514c1.181.757 1.181 3.112 0 3.944l-19.545 13.764c-1.13.796-2.443-.227-2.443-1.912z" />
      </g>
    </svg>
  </template>
</template>
