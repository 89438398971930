<script setup lang="ts">
export type BaseButtonType = "primary" | "secondary" | "danger" | "icon";
export type BaseButtonSize = "small" | "medium";
export type BaseButtonTag = "button" | "a" | "nuxt-link" | "div" | "span";
export interface BaseButtonProps {
  /**
   * Required. Used to create test id's for the button.
   */
  name: string;
  /**
   * Defines the title attribute value for the button.
   *
   * Also user for `aria-title`.
   */
  title?: string;
  /**
   * Defines if button is disabled or not.
   */
  disabled?: boolean;
  /**
   * Sets button style to outline.
   */
  outline?: boolean;
  /**
   * Sets button style to outline.
   */
  link?: boolean;
  /**
   * Sets button width to 100%.
   */
  fullWidth?: boolean;
  /**
   * Makes button fully rounded
   */
  fullRounded?: boolean;
  /**
   * Defines the component tag for the button.
   */
  tag?: BaseButtonTag;
  /**
   * Defines button type.
   */
  type?: BaseButtonType;
  attrType?: string;
  /**
   * Defines button size;
   */
  size?: BaseButtonSize;
}

const props = withDefaults(defineProps<BaseButtonProps>(), {
  tag: "button",
  type: "primary",
  fullWidth: false,
  size: "medium",
});
const emit = defineEmits(["click"]);

const classes = computed(() => {
  const base = "baseButton";
  const buttonType = props.outline ? `${props.type}Outline` : props.type;
  const buttonSize = `${base}-${props.size}`;
  return [
    base,
    buttonSize,
    `${base}-${buttonType}`,
    "ottButton",
    {
      [`${base}-w-full`]: props.fullWidth,
      [`${base}-rounded-full`]: props.fullRounded,
      [`${base}-link`]: props.link,
      [`${base}-outline`]: props.outline,
    },
  ];
});

function onClick(event: Event) {
  emit("click", event);
}

const rootRef = ref(null);
</script>

<template>
  <BaseNuxtLink
    v-if="props.tag === 'nuxt-link'"
    ref="rootRef"
    :type="attrType"
    :name="props.name"
    :title="props.title"
    :aria-label="props.title"
    :data-test-id="props.name && `pxlBtn-${props.name}`"
    :class="classes"
    :disabled="props.disabled ? true : undefined"
    :aria-disabled="props.disabled ? props.disabled : undefined"
  >
    <slot />
  </BaseNuxtLink>
  <component
    :is="props.tag"
    v-else
    ref="rootRef"
    :type="attrType"
    :name="props.name"
    :title="props.title"
    :aria-label="props.title"
    :data-test-id="props.name && `pxlBtn-${props.name}`"
    :class="classes"
    :disabled="props.disabled ? true : undefined"
    :aria-disabled="props.disabled ? props.disabled : undefined"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<style lang="scss">
.baseButton {
  --baseButton__small: 0.19rem 0.6602rem;
  --baseButton__medium: 0.6878rem 1rem;

  --baseButton__primary: var(--base-primary-accent-color, var(--base-primary-color));
  --baseButton__secondary: var(--base-secondary-color, #a9b7d1);
  --baseButton__danger: var(--base-error-color, #d22018);

  /* Primary */
  --baseButton__primary_color: #ffffff;
  --baseButton__primary_backgroundColor: var(--baseButton__primary);
  --baseButton__primary_border: 1px solid transparent;

  /* Primary on Hover */
  --baseButton__primary_color_onHover: #ffffff;
  --baseButton__primary_backgroundColor_onHover: var(--baseButton__primary);

  /* Primary Outline */
  --baseButton__primaryOutline_color: var(--baseButton__primary);
  --baseButton__primaryOutline_backgroundColor: transparent;
  --baseButton__primaryOutline_border: 1px solid var(--baseButton__primary);

  /* Primary Outline on Hover */
  --baseButton__primaryOutline_color_onHover: #ffffff;
  --baseButton__primaryOutline_backgroundColor_onHover: var(--baseButton__primary);

  /* Secondary */
  --baseButton__secondary_color: var(--base-text-color);
  --baseButton__secondary_backgroundColor: var(--baseButton-secondary-bgColor);
  --baseButton__secondary_border: 1px solid transparent;

  /* Secondary on Hover */
  --baseButton__secondary_color_onHover: #000000;
  --baseButton__secondary_backgroundColor_onHover: var(--baseButton__secondary);

  /* Secondary Outline */
  --baseButton__secondaryOutline_color: var(--baseButton__secondary);
  --baseButton__secondaryOutline_backgroundColor: transparent;
  --baseButton__secondaryOutline_border: 1px solid var(--baseButton__secondary);

  /* Secondary Outline on Hover */
  --baseButton__secondaryOutline_color_onHover: #ffffff;
  --baseButton__secondaryOutline_backgroundColor_onHover: var(--baseButton__secondary);

  /* Danger */
  --baseButton__danger_color: #ffffff;
  --baseButton__danger_backgroundColor: var(--baseButton__danger);
  --baseButton__danger_border: 1px solid transparent;

  /* Danger on Hover */
  --baseButton__danger_color_onHover: #ffffff;
  --baseButton__danger_backgroundColor_onHover: var(--baseButton__danger);

  /* Danger Outline */
  --baseButton__dangerOutline_color: var(--baseButton__danger);
  --baseButton__dangerOutline_backgroundColor: transparent;
  --baseButton__dangerOutline_border: 1px solid var(--baseButton__danger);

  /* Danger Outline on Hover */
  --baseButton__dangerOutline_color_onHover: #ffffff;
  --baseButton__dangerOutline_backgroundColor_onHover: var(--baseButton__danger);

  // inline-block
  @apply rounded cursor-pointer transition;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.75;
  }

  &[disabled] {
    opacity: 0.55;
    cursor: not-allowed;
  }

  @each $type in (primary, primaryOutline, secondary, secondaryOutline, danger, dangerOutline) {
    &.baseButton-#{$type} {
      border: var(--baseButton__#{$type}_border);
      color: var(--baseButton__#{$type}_color);
      background-color: var(--baseButton__#{$type}_backgroundColor);

      &:hover {
        color: var(--baseButton__#{$type}_color_onHover);
        background-color: var(--baseButton__#{$type}_backgroundColor_onHover);
      }

      &.baseButton-link {
        color: var(--baseButton__#{$type});
      }
    }
  }

  @each $size in (small, medium) {
    &.baseButton-#{$size} {
      padding: var(--baseButton__#{$size});
    }
  }
  &.baseButton-link {
    display: inline;
    box-shadow: none;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  &.baseButton-icon,
  &.baseButton-iconOutline {
    padding: 0;
    border: none;
    background: none;
  }

  &.baseButton-w-full {
    @apply w-full;
  }

  &.baseButton-rounded-full {
    @apply rounded-full;
  }
}
</style>
