export const ORDER = {
  desc: "desc",
  asc: "asc",
} as const;

export const SORT = {
  event_date: "event_date",
  event_date_added: "event_date,added",
  aired_date: "aired_date",
  update_date: "update_date",
  order: "order",
  aired_date_added: "aired_date,added",
  added: "added",
  title: "title",
  created: "created",
} as const;
