export const polyfillObjectFromEntries = () => {
  if (!(Object as any).fromEntries) {
    Object.defineProperty(Object, "fromEntries", {
      value(entries: Iterable<readonly any[]>): any {
        if (!entries || !(Symbol.iterator in entries))
          throw new Error("Object.fromEntries() requires a single iterable argument");

        const o: { [key: string]: any } = {};

        for (const [k, v] of entries)
          o[k] = v;

        return o;
      },
    });
  }
};
