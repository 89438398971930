<script setup lang="ts">
import useSocketIO from "~/hooks/use-socket-io";
import useDeviceSocket from "~/hooks/use-socket-io/use-device-socket";
import { initAppleSdk } from "~/services/auth/apple";
import { initFacebookSdk } from "~/services/auth/facebook";
import { initGoogleSdk } from "~/services/auth/google";
import DeviceAPI from "~/services/device";
import useUserStore from "~/stores/user";
import SmartAppBanner from "./components/SmartAppBanner.vue";
import { THEME_COLOR } from "./constants";

const route = useRoute();
const router = useRouter();
const color = useColorMode();
const appConfig = useRuntimeConfig().public;
const userStore = useUserStore();
const { locale: currentLocale } = useI18n();
const storeUrl = route.query.storeUrl as string;

function onStoreRedirect() {
  if (!storeUrl || storeUrl === "undefined")
    return;

  window.location.href = storeUrl;
}

function onDeviceLimitError(error?: Error) {
  // eslint-disable-next-line no-console
  console.log(error);

  if (route.name === "auth-login-limit")
    return;

  if ((error as Error & { code?: string })?.code === "refresh_token_failed")
    return;

  userStore.logout();
  router.push({ path: "/auth/login/", query: { deviceNoLongerAllowedError: "true" } });
}

onMounted(() => {
  onStoreRedirect();

  if (appConfig.APP_CUSTOMER.app_config.enable_auth_via_facebook && appConfig.APP_CUSTOMER.app_config.app_id_facebook)
    initFacebookSdk();

  if (appConfig.APP_CUSTOMER.app_config.enable_auth_via_google && appConfig.APP_CUSTOMER.app_config.app_client_id_for_google)
    initGoogleSdk();

  if (appConfig.APP_CUSTOMER.app_config.enable_auth_via_apple && appConfig.APP_CUSTOMER.app_config.app_client_id_for_apple)
    initAppleSdk();

  if (!appConfig.APP_CUSTOMER.enable_white_theme && color?.preference)
    color.preference = "dark";

  const socket = useSocketIO();

  if (socket)
    useDeviceSocket(socket, { onDeviceRemoved: onDeviceLimitError });

  if (userStore.isAuthenticated)
    DeviceAPI.checkCurrent().catch(error => onDeviceLimitError(error));
});

useHead({
  meta: [
    { name: "theme-color", content: computed(() => (color.value === THEME_COLOR.DARK ? "#0f1624" : "#f4f7fc")) },
  ],
  // @ts-expect-error FIXME:
  htmlAttrs: [{
    lang: computed(() => currentLocale.value || "en"),
    dir: computed(() => currentLocale.value === "he" ? "rtl" : "ltr"),
  }],
});
</script>

<template>
  <UModals />
  <SmartAppBanner />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
