import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import useEventSocket from "~/hooks/use-socket-io/use-event-socket";
import useUserSocket from "./use-user-socket";

export enum SOCKET_IO_EVENT {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
}

// Copy pasted from v1
// TODO: Remove unused
export enum SOCKET_EVENT {
  REGISTER_USER = "register-user",
  UNREGISTER_USER = "unregister-user",
  REMOVE_USER_DEVICE = "remove-user-device",
  JOIN_USER_ROOM = "join-user-room",
  LEAVE_USER_ROOM = "leave-user-room",
  JOIN_EVENT_ROOM = "join-event-room",
  LEAVE_EVENT_ROOM = "leave-event-room",
  EVENT_URL_CHANGE = "event-url-change",
  COMMENT_ON_MEDIA = "comment-on-media",
  COMMENT_ON_MEDIA_DELETED = "comment-on-media-deleted",
  COMMENT_ON_MEDIA_UPDATED = "comment-on-media-updated",
  UPDATE_EVENT = "update-event",
}

export default function useSocketIO(): Socket | null {
  const appConfig = useRuntimeConfig().public;
  if (!appConfig.APP_CUSTOMER.app_config.socket_base) {
    console.warn("`SOCKET_BASE` not found! Unable to use SocketIO!");

    return null;
  }

  const socket = io(appConfig.APP_CUSTOMER.app_config.socket_base, { transports: ["websocket"], path: "/ws" });
  // eslint-disable-next-line no-console
  const log = (event: string, data: Event) => console.log({ event, data });

  socket.on<any>(SOCKET_IO_EVENT.CONNECT, (event: any) => log(SOCKET_IO_EVENT.CONNECT, event as Event));
  socket.on<any>(SOCKET_IO_EVENT.DISCONNECT, (event: any) => log(SOCKET_IO_EVENT.DISCONNECT, event as Event));
  socket.on<any>(SOCKET_EVENT.JOIN_USER_ROOM, (event: any) => log(SOCKET_EVENT.JOIN_USER_ROOM, event as Event));
  socket.on<any>(SOCKET_EVENT.LEAVE_USER_ROOM, (event: any) => log(SOCKET_EVENT.JOIN_USER_ROOM, event as Event));
  socket.on<any>(SOCKET_EVENT.REMOVE_USER_DEVICE, (event: any) => log(SOCKET_EVENT.REMOVE_USER_DEVICE, event as Event));
  socket.on<any>(SOCKET_EVENT.EVENT_URL_CHANGE, (event: any) => log(SOCKET_EVENT.EVENT_URL_CHANGE, event as Event));
  socket.on<any>(SOCKET_EVENT.UPDATE_EVENT, (event: any) => log(SOCKET_EVENT.UPDATE_EVENT, event as Event));

  useUserSocket(socket);
  useEventSocket(socket);

  return socket;
}
