import useUserStore from "~/stores/user";

export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore();
  const appConfig = useRuntimeConfig().public;
  const isEnabledFavoriteClubSelection = appConfig.APP_CUSTOMER.enable_favorite_club_selection;
  if (isEnabledFavoriteClubSelection && userStore.isAuthenticated && !userStore.user?.favoriteClubId) {
    if (to.path !== "/club/")
      return navigateTo({ path: "/club/" });
  }
});
