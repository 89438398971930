interface AnyObject {
  [key: string]: any;
}

export function formatObjectToQueryString<T extends AnyObject>(obj: T): string {
  return Object.keys(obj)
    .reduce((acc: string[], key) => {
      const value = obj && obj[key];
      if (value !== undefined) {
        if (Array.isArray(value))
          value.forEach(v => acc.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`));
        else
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return acc;
    }, [])
    .join("&");
}
