import { DATE_RANGE, EVENT_STATUS, EVENT_TYPE } from "~/constants";
import { formatEventSubCategory, formatPaginatedResponseItems, formatRequestOptions } from "../format";

const Event = {
  params(options: IRequestOptionsEvents): IRequestParamsEvents {
    const params: IRequestParamsEvents = formatRequestOptions(options);

    params.filters = {
      "identities.id": options.subCategoryId,
      "identities.type": options.mainCategoryId,
      "media_type": options.contentType,
      "search.teams.id": options.teamId,
      "event_id": options.eventId,
    };

    if (options.dateRange)
      params.filters.event_date = formatEventDateRange(options.dateRange);

    params.filters.status = options.type;

    if (options.sportTypeFilter?.length)
      params["filters[SportType]"] = options.sportTypeFilter;

    if (options.countOnly) {
      params.entries = false;
      params.count = true;
    }

    return params;
  },

  format(item: IResponseEvent): IModelEvent {
    const appConfig = useRuntimeConfig().public;
    const isEnableTeamShortName = appConfig.APP_CUSTOMER.enable_teams_short_name;
    const isRealKickoffTimeEnabled = appConfig.APP_CUSTOMER.enable_real_kickoff_time;
    const isEnableMatchStats = Boolean(appConfig.APP_CUSTOMER.enable_match_stats);
    const eventStatus = getEventStatus(item.status);
    const isLeaguesView = Boolean(appConfig.APP_CUSTOMER.enable_leagues_view);
    const isEnabledAds = Boolean(appConfig.APP_CUSTOMER.web_sdk.enable_ads);
    const isExternal = item.eventType === "externalContent";
    const categories = item.mediaIdentities ? item.mediaIdentities.map(formatEventSubCategory) : [];
    const ads = isEnabledAds ? getEventAds(item, eventStatus) : [];
    const isOtherGameType = item.gameType === "other" || Boolean(!item.eventTeams?.homeTeam && !item.eventTeams?.awayTeam);
    const isArchived = item.status === "archived";
    const metaTitle = !isOtherGameType && isLeaguesView && categories.length && categories[0]?.identityIds?.length
      ? `${categories[0].identityIds[0].title}•${categories[0].title}`
      : item.title;
    const team1Name = isEnableTeamShortName && item.eventTeams?.homeTeam?.shortName ? item.eventTeams?.homeTeam?.shortName : item.eventTeams?.homeTeam?.name;
    const team2Name = isEnableTeamShortName && item.eventTeams?.awayTeam?.shortName ? item.eventTeams?.awayTeam?.shortName : item.eventTeams?.awayTeam?.name;
    const gameStartDate = isRealKickoffTimeEnabled && item.gameStartDate ? item.gameStartDate : item.event_date;
    const hasGenderFilter = appConfig.APP_CUSTOMER.enable_gender_classification;

    return {
      id: item._id,
      title: item.title,
      ads,
      metaTitle,
      commerceCoverage: item.commerce_type || ["commerce_free_media"],
      date: item.event_date,
      gameStartDate,
      eventId: item.event_id,
      referenceId: item.reference_id,
      sportType: item.sportType,
      eventType: item.media_type,
      gameType: item.gameType,
      eventStatus,
      isExternal,
      isArchived,
      gender: hasGenderFilter ? item.gender : undefined,
      eventDescription: item.description,
      isEventAvailableForUser: item.availableForUser || item.media_type === EVENT_TYPE.USER_CLIP,
      isEventAvailableInUserRegion: item.availableInUserRegion,
      isEnabledVidswapStats: (item.integrations?.vidswap?.enabled && isEnableMatchStats) || false,
      geniusId: item.integrations?.genius?.id,
      imageUrl: item.content?.PosterH,
      team1Name,
      team2Name,
      team1LogoUrl: item.eventTeams?.homeTeam?.logo,
      team2LogoUrl: item.eventTeams?.awayTeam?.logo,
      team1Color: item.eventTeams?.homeTeam?.color,
      team2Color: item.eventTeams?.awayTeam?.color,
      team1Id: item.eventTeams?.homeTeam?.teamId,
      team2Id: item.eventTeams?.awayTeam?.teamId,
      source: {
        hd: item?.content?.HLSStream,
        pano: item?.content?.PanoStream,
        poster: item?.content?.PosterH,
        clip: item?.content?.MezzanineVideo,
        epg: item?.content?.EPG,
      },
      categories,
    };
  },

  formatMediaEvent(item: IResponseMediaEvent): IModelMediaEvent {
    const baseEvent = Event.format(item);
    return {
      ...baseEvent,
      mediaType: item.type,
      mediaSubType: item.subtype,
      playerNumber: item.playerNumber,
      playerColor: item.playerColor,
    };
  },

  formatPaginated<T, U>(items: IPaginatedResponse<T>, format: (item: T) => U): IPaginatedResponse<U> {
    return formatPaginatedResponseItems(items, format);
  },

  mergeSuggested(response: IContentResponse<IResponseEvent>, excludedItemId?: string): IPaginatedResponse<IModelEvent> {
    const content: IPaginatedContent = {
      entryCount: 0,
      itemsPerPage: 0,
      startIndex: 0,
      count: 0,
      nextExists: false,
      entries: [],
    };
    const entries: IResponseEvent[] = [];
    const addedIds: string[] = [];

    for (const item of Object.values(response.content)) {
      if (Array.isArray(item.entries)) {
        const filteredEntries = item.entries.filter((entry: IResponseEvent) => {
          if (entry._id === excludedItemId || addedIds.includes(entry._id))
            return false;
          addedIds.push(entry._id);
          return true;
        });

        entries.push(...filteredEntries);
      }
    }

    content.entries = entries;
    content.count = entries.length || 0;

    return Event.formatPaginated({
      content,
      message: response.message,
      error: response.error,
    }, Event.format);
  },

  formatScoreStats(stats?: IResponseEventGameStats): IEventScoreStats | null {
    if (!stats)
      return null;

    const totalScore = stats.groups?.[0]?.metrics.find(team => team.shortName === "Total");

    return {
      team_home: { ...stats.homeTeam, total: totalScore?.homeTeamValue.text },
      team_away: { ...stats.awayTeam, total: totalScore?.awayTeamValue.text },
      stats: stats.groups?.[0]?.metrics,
    };
  },

  formatGameStats(stats?: IResponseEventGameStats): IEventGameStats | null {
    if (!stats)
      return null;

    const totalScore = stats.groups?.[0]?.metrics.find(team => team.shortName === "G");

    return {
      team_home: { ...stats.homeTeam, total: totalScore?.homeTeamValue.text },
      team_away: { ...stats.awayTeam, total: totalScore?.awayTeamValue.text },
      stats: stats.groups?.[0]?.metrics.map(formatGameStatItem),
    };
  },
};

function getEventAds(event: IResponseEvent, status: TEventStatus): IModelAds[] {
  const ads = event.ads?.reduce((acc: IModelAds[], item) => {
    const source = item.content[0].downloadUrl;
    const interval = item.adConfig.interval || 0;
    const isEventCommerceMatch = event.commerce_type.includes(item.categories[0].name);
    const isEventStatusMatch = item.adConfig.target.includes(status);
    const isLive = status === EVENT_STATUS.LIVE;
    const isLiveMidRoll = isLive && interval > 0;
    const timeOffset = isLive ? interval : interval + 1;
    const isAlreadyUnpublished = item.unpublish_date ? Date.now() > Number(item.unpublish_date) : false;
    const isAdsAvailable = source && isEventCommerceMatch && isEventStatusMatch && !isLiveMidRoll && !isAlreadyUnpublished;

    if (isAdsAvailable)
      acc.push({ source, timeOffset });
    return acc;
  }, []);

  return ads || [];
}

function getEventStatus(status: string): TEventStatus {
  if (status === EVENT_STATUS.UPCOMING)
    return EVENT_STATUS.UPCOMING;
  else if (status === EVENT_STATUS.LIVE)
    return EVENT_STATUS.LIVE;
  else if (status === EVENT_STATUS.CHANNEL)
    return EVENT_STATUS.CHANNEL;
  else
    return EVENT_STATUS.VOD;
}

function formatEventDateRange(dateRange: TDateRange): string | undefined {
  const now = new Date();
  const roundToMinute = (date: number) => Math.floor(date / 60000) * 60000 + 1000;
  if (!dateRange || dateRange === DATE_RANGE.ANYTIME) {
    return undefined;
  }
  else if (dateRange === DATE_RANGE.TODAY) {
    return `${roundToMinute(now.setHours(0, 0, 0, 0))}~${roundToMinute(now.getTime())}`;
  }
  else if (dateRange === DATE_RANGE.YESTERDAY) {
    return `${roundToMinute(new Date(new Date().setDate(now.getDate() - 1)).setHours(0, 0, 0, 0))}~${roundToMinute(
      now.setHours(0, 0, 0, 0),
    )}`;
  }
  else if (dateRange === DATE_RANGE.PAST_7_DAYS) {
    return `${roundToMinute(now.setDate(now.getDate() - 7))}~${roundToMinute(now.getTime())}`;
  }
  else if (dateRange === DATE_RANGE.PAST_30_DAYS) {
    return `${roundToMinute(now.setDate(now.getDate() - 30))}~${roundToMinute(now.getTime())}`;
  }
  else if (dateRange === DATE_RANGE.PAST_90_DAYS) {
    return `${roundToMinute(now.setDate(now.getDate() - 90))}~${roundToMinute(now.getTime())}`;
  }

  return "";
}

function formatGameStatItem(statItem: IResponseEventGameStats["groups"][0]["metrics"][0]): IEventGameStatsS {
  const isPercentageValue = statItem.homeTeamValue.text.slice(-1) === "%";
  const homeTeamValue = isPercentageValue ? Number.parseInt(statItem.homeTeamValue.text) : statItem.homeTeamValue.number;
  const awayTeamValue = isPercentageValue ? Number.parseInt(statItem.awayTeamValue.text) : statItem.awayTeamValue.number;
  const maxValue = isPercentageValue ? 100 : statItem.homeTeamValue.number + statItem.awayTeamValue.number;

  return {
    name: statItem.name,
    filterId: statItem.filterId,
    shortName: statItem.shortName,
    homeTeamValue,
    awayTeamValue,
    valueSuffix: isPercentageValue ? "%" : "",
    maxValue,
  };
}

export default Event;
