<script setup>
const LinkWithTrailingSlash = defineNuxtLink({
  /**
   * It's a custom nuxt link which append trailing slash to each route.
   */
  trailingSlash: "append",
});
</script>

<template>
  <LinkWithTrailingSlash>
    <slot />
  </LinkWithTrailingSlash>
</template>
