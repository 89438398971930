<script setup lang="ts">
/* eslint-disable max-len */
export interface IconClose {
  fill?: string;
  width?: number;
  height?: number;
}
const props = withDefaults(defineProps<IconClose>(), { fill: "#A9B7D1", width: 20, height: 20 });
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 20 20">
    <path
      d="M2.342 17.657c.23.229.458.343.8.343.343 0 .572-.114.8-.343L10 11.6l6.057 6.057c.229.229.571.343.8.343.229 0 .572-.114.8-.343a1.105 1.105 0 0 0 0-1.6L11.6 10l6.057-6.057a1.105 1.105 0 0 0 0-1.6 1.105 1.105 0 0 0-1.6 0L10 8.4 3.943 2.343a1.105 1.105 0 0 0-1.6 0 1.105 1.105 0 0 0 0 1.6L8.4 10l-6.057 6.057a1.105 1.105 0 0 0 0 1.6z"
      :fill="props.fill"
      fill-rule="evenodd"
    />
  </svg>
</template>
