export const formatPaginatedResponseItems = <T>(
  response: IPaginatedResponse<any>,
  formatFn: (res: any) => T,
): IPaginatedResponse<T> => ({
  content: {
    entryCount: response.content.entryCount,
    totalCount: response.content.totalCount,
    itemsPerPage: response.content.itemsPerPage,
    startIndex: response.content.startIndex,
    count: response.content.count,
    nextExists: response.content.nextExists,
    entries: response.content.entries.map(formatFn),
  },
  error: response.error,
  message: response.message,
});

export const formatContentResponse = <T>(
  response: IContentArrayResponse<any>,
  formatFn: (res: any) => T,
): IContentArrayResponse<T> => ({
  content: response.content.map(formatFn),
  error: response.error,
  message: response.message,
});

export const returnPaginatedResponse = <T>(items: T[]): IPaginatedResponse<T> => ({
  content: {
    entryCount: items.length,
    itemsPerPage: items.length,
    nextExists: false,
    startIndex: 0,
    count: items.length,
    entries: items,
  },
  error: false,
  message: "",
});
