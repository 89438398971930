<script setup lang="ts">
import { THEME_COLOR } from "~/constants";

export interface BaseErrorProps {
  context?: "page" | "event" | "team" | "category" | "league";
  message?: string;
}

const props = withDefaults(defineProps<BaseErrorProps>(), {
  context: "page",
});

const { t } = useI18n();
const color = useColorMode();
const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="flex items-center justify-center py-56">
    <div class="flex flex-col gap-6 text-center text-main">
      <img v-if="color?.value === THEME_COLOR.LIGHT" width="382" height="145" src="~/assets/images/page_not_found_light.png">
      <img v-else width="382" height="145" src="~/assets/images/page_not_found_dark.png">
      <div class="flex flex-col gap-3">
        <p class="text-2xl font-bold">
          {{ t(`errors.${props.context}_not_found`) }}
        </p>
        <p class="text-sm font-medium">
          {{ t(`errors.could_not_find_${props.context}`) }}
        </p>
        <BaseAlert v-if="props.message" type="danger" :text="props.message" />
      </div>
      <div>
        <BaseButton name="main-page" class="text-sm font-bold" @click="handleError()">
          {{ t("button.go_to_homepage") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
