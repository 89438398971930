import useRequest from "~/hooks/use-request";
import useUserStore from "~/stores/user";
import { formatContentResponse } from "../format";
import Event from "./event.model";

const SelectedEventsAPI = {
  load(options?: IRequestOptionsEvents): Promise<IContentArrayResponse<IModelEvent>> {
    const userStore = useUserStore();
    let params;
    if (options) {
      params = Event.params(options);
      params.filters = undefined;
    }
    const { request } = useRequest();

    return request
      .get<IContentArrayResponse<IResponseEvent>>("/api/user/get_watchlist", { query: params })
      .then((response) => {
        const selectedVideos = formatContentResponse(response, Event.format);
        selectedVideos.content.forEach(item => (userStore.selected[item.id] = true));

        return selectedVideos;
      });
  },

  selectVideo(videoId: string): Promise<void> {
    const { request } = useRequest();
    const userStore = useUserStore();
    const params = { video_id: videoId };

    return request
      .post<IContentArrayResponse<void>>("/api/user/add_to_watchlist", { body: params })
      .then(() => {
        userStore.selected[videoId] = true;
      });
  },

  unselectVideo(videoId: string): Promise<void> {
    const { request } = useRequest();
    const userStore = useUserStore();
    const params = { video_id: videoId };

    return request
      .post<IContentArrayResponse<void>>("/api/user/remove_from_watchlist", { body: params })
      .then(() => {
        delete userStore.selected[videoId];
      });
  },
};
export default SelectedEventsAPI;
