<script setup lang="ts">
import { THEME_COLOR } from "~/constants";

export type BaseLogoPropsSize = number | "full" | "auto";
export interface BaseLogoProps {
  src?: string;
  alt?: string;
  fallbackSrc?: string;
  width?: BaseLogoPropsSize;
  height?: BaseLogoPropsSize;
  withHover?: boolean;
  loading?: "lazy" | "eager";
}

const props = withDefaults(defineProps<BaseLogoProps>(), {
  width: 320,
  height: 180,
  alt: "base image",
  loading: "lazy",
});
const color = useColorMode();
const emit = defineEmits(["error"]);
const isImageLoadError = ref(false);
const currentSrc = ref(props.src || props.fallbackSrc);
const canShowImage = computed(() => (!isImageLoadError.value || currentSrc.value) && props.src);

const onImageLoadError = () => {
  isImageLoadError.value = true;
  emit("error");

  if (currentSrc.value === props.fallbackSrc)
    return emit("error");

  currentSrc.value = props.fallbackSrc;
};

const getStyleValue = (value: BaseLogoPropsSize) => (value === "full" ? "100%" : value === "auto" ? "auto" : `${value}px`);
const getAttrStyleValue = (value: BaseLogoPropsSize) => (value === "full" ? "100" : value === "auto" ? "auto" : value);
</script>

<template>
  <img
    v-if="canShowImage"
    :src="currentSrc"
    :alt="props.alt"
    :loading="props.loading"
    :style="{
      width: getStyleValue(props.width),
      height: getStyleValue(props.height),
    }"
    :width="getAttrStyleValue(props.width)"
    :height="getAttrStyleValue(props.height)"
    @error="onImageLoadError()"
  >
  <IconVideo v-else :is-dark-theme="color.value === THEME_COLOR.DARK" />
</template>
