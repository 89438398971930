<script setup>
const props = defineProps({
  error: Object,
});

const context = computed(() => props.error.data?.context);
</script>

<template>
  <div class="min-h-screen h-full bg-[var(--base-background-color)]">
    <div class="main-container">
      <BaseError :context="context" />
    </div>
  </div>
</template>
