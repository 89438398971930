import revive_payload_client_Ebx9D15sQK from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rQaoIUsklU from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_u2gfqK4ySD from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vdarZi0uZN from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt-site-config@2.2.21/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_qQUyExow3s from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_nOVKzquQtf from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_1Rfn8eJnIw from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.6.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ott-web-portal2/ott-web-portal2/.nuxt/components.plugin.mjs";
import prefetch_client_SmWY9NRC2Y from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WQMXwyIzLG from "/home/runner/work/ott-web-portal2/ott-web-portal2/modules/mercadopago/runtime/plugin.ts";
import plugin_z1txnrVC55 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt-bugsnag@5.9.2/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import motion_vfcqzW6B3g from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@vueuse+motion@2.2.6/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import composition_Dl7GFHMnvv from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_6yOhG8bU0K from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_eNelkHx7DD from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_bJeyp84YM6 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_jxux75lg2kqmiu46cttywdtvhy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_client_vQNOeunMWK from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/auto-animate.client.ts";
import floating_vue_BiOD74u9sH from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/floating-vue.ts";
import google_analytics_client_KruZkwwzId from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/google-analytics.client.ts";
import modals_RaVbrZQ2Fe from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/modals.ts";
import one_signal_client_jB01tVDMgQ from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/one-signal.client.ts";
import persistedstate_client_O5MsxXlNtZ from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/persistedstate.client.ts";
import polyfills_client_xo6BY28vLt from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/polyfills.client.ts";
import segment_client_PKspGsfJUR from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/segment.client.ts";
export default [
  revive_payload_client_Ebx9D15sQK,
  unhead_rQaoIUsklU,
  router_u2gfqK4ySD,
  _0_siteConfig_vdarZi0uZN,
  payload_client_qQUyExow3s,
  check_outdated_build_client_nOVKzquQtf,
  plugin_vue3_1Rfn8eJnIw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SmWY9NRC2Y,
  plugin_WQMXwyIzLG,
  plugin_z1txnrVC55,
  motion_vfcqzW6B3g,
  composition_Dl7GFHMnvv,
  i18n_6yOhG8bU0K,
  plugin_client_eNelkHx7DD,
  chunk_reload_client_bJeyp84YM6,
  auto_animate_client_vQNOeunMWK,
  floating_vue_BiOD74u9sH,
  google_analytics_client_KruZkwwzId,
  modals_RaVbrZQ2Fe,
  one_signal_client_jB01tVDMgQ,
  persistedstate_client_O5MsxXlNtZ,
  polyfills_client_xo6BY28vLt,
  segment_client_PKspGsfJUR
]