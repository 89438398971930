export const formatProviderResponse = (access_token: string, provider_name: TAuthProvider): IProviderSocialResponse => {
  return {
    provider_name,
    token_type: "access_token",
    access_token,
  };
};

export const formatAppleProviderResponse = (userInfo: any, id_token: string): IProviderAppleResponse => {
  return {
    provider_name: "apple",
    token_type: "id_token",
    id_token,
    full_name: `${userInfo?.name?.firstName || ""} ${userInfo?.name?.lastName || ""}`,
  };
};
