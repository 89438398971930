import { formatObjectToQueryString } from "~/utils/query";

enum REQUEST_STATE {
  INIT = "REQUEST_INIT",
  IN_PROGRESS = "REQUEST_IN_PROGRESS",
  ERROR = "REQUEST_ERROR",
  SUCCESS = "REQUEST_SUCCESS",
}

type DataType = "text" | "json" | "blob" | "arrayBuffer" | "formData";
// type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS';

export interface RequestOptions extends Record<string, any> {
  query?: Record<string, any>;
  body?: any;
  baseUrl?: string;
  responseType?: DataType;
}

export interface GlobalRequestOptions {
  baseUrl?: string;
  signal?: AbortController["signal"];
  beforeRequest?: (fetchOptions: RequestInit, requestOptions: RequestOptions) => Promise<void>;
  formatResponse?: (fetchResponse: Response, requestOptions: RequestOptions) => void;
  onError?: (
    fetchError: Response,
    fetchOptions: RequestInit,
    requestOptions: RequestOptions,
    retry: (options?: Record<string, any>) => Promise<unknown>,
    error: {
      message: string;
      code: string;
    }
  ) => void;
}

export default function useRequest(globalOptions: GlobalRequestOptions) {
  const requestState = ref(REQUEST_STATE.INIT);
  const isError = computed(() => requestState.value === REQUEST_STATE.ERROR);
  const loading = computed(() => requestState.value === REQUEST_STATE.IN_PROGRESS);
  const error = ref({
    message: "",
    code: "",
  });

  async function makeRequest<T>(url: string, options: RequestOptions = {}, initFetchOptions?: RequestInit): Promise<T> {
    error.value.message = "";
    error.value.code = "";
    requestState.value = REQUEST_STATE.IN_PROGRESS;
    const baseUrl = options.baseUrl || globalOptions.baseUrl || "/";
    const defaultErrorCode = "default_error_message";
    const defaultFetchOptions: RequestInit = {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const defaultRequestOption: RequestOptions = {
      setAuthorization: true,
    };

    const fetchOptions = Object.assign(defaultFetchOptions, initFetchOptions);
    const requestOption = Object.assign(defaultRequestOption, options);

    let fetchUrl = `${baseUrl}${url}`;

    if (requestOption.query) {
      const queryString = formatObjectToQueryString(requestOption.query);

      if (queryString.length)
        fetchUrl = fetchUrl.concat(`?${queryString}`);
    }

    if (requestOption.body)
      fetchOptions.body = JSON.stringify(requestOption.body);

    if (globalOptions?.beforeRequest)
      await globalOptions?.beforeRequest(fetchOptions, requestOption);

    if (globalOptions?.signal)
      fetchOptions.signal = globalOptions.signal;

    return new Promise((resolve, reject) => {
      fetch(fetchUrl, fetchOptions)
        .then(async (fetchResponse) => {
          if (!fetchResponse.ok)
            return Promise.reject(fetchResponse);

          if (fetchResponse.status === 204)
            return Promise.resolve({});

          const responseType = requestOption.responseType || "json";

          if (globalOptions?.formatResponse)
            return globalOptions?.formatResponse(fetchResponse, requestOption);

          return fetchResponse[responseType]();
        })
        .then(response => resolve(response))
        .catch(async (fetchResponseError) => {
          requestState.value = REQUEST_STATE.ERROR;

          const responseType = requestOption.responseType || "json";

          if (fetchResponseError[responseType]) {
            const fetchError = await fetchResponseError[responseType]().catch(() => {});

            error.value.code = fetchError?.messageErrorCode || defaultErrorCode;
            error.value.message = fetchError?.message;
          }
          else {
            error.value.code = defaultErrorCode;
          }

          const retry = <T>(payload?: Record<string, any>): Promise<T> => makeRequest(url, Object.assign({}, requestOption, payload), initFetchOptions);

          if (globalOptions.onError)
            return resolve(globalOptions.onError(fetchResponseError, fetchOptions, requestOption, retry, error.value) as T);

          reject(new Error(JSON.stringify({ message: error.value.message, code: error.value.code })));
        })
        .finally(() => {
          if (requestState.value !== REQUEST_STATE.ERROR)
            requestState.value = REQUEST_STATE.SUCCESS;
        });
    });
  }

  const request = {
    get: <T>(url: string, options?: RequestOptions) => makeRequest<T>(url, options, { method: "GET" }),
    post: <T>(url: string, options?: RequestOptions) => makeRequest<T>(url, options, { method: "POST" }),
    put: <T>(url: string, options?: RequestOptions) => makeRequest<T>(url, options, { method: "PUT" }),
    patch: <T>(url: string, options?: RequestOptions) => makeRequest<T>(url, options, { method: "PATCH" }),
    delete: <T>(url: string, options?: RequestOptions) => makeRequest<T>(url, options, { method: "DELETE" }),
  };

  return { isError, loading, error, request };
}
