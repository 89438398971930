export const EVENT_TYPE = {
  EVENT: "event",
  CLIP: "clip",
  USER_CLIP: "user_clip",
  USER_HIGHLIGHT: "user_highlight",
  HIGHLIGHT: "highlight",
} as const;

export const EVENT_STATUS = {
  LIVE: "live",
  UPCOMING: "upcoming",
  VOD: "vod",
  CHANNEL: "channel",
} as const;

export const GAME_TYPE = {
  GAME: "game",
  TRAINING: "training",
  OTHER: "other",
} as const;

export const EVENT_MEDIA_TYPE = {
  GAME: "game",
  PLAYER: "player",
  USER: "user",
} as const;

export const EVENT_MEDIA_SUB_TYPE = {
  AUTO: "auto",
  RECAP: "recap",
  CONDENSED: "condensed",
  CLIP: "clip",
  HIGHLIGHT: "highlight",
} as const;
