import { useOneSignal } from "@onesignal/onesignal-vue3";
import { SOCIAL_PROVIDER } from "~/constants";
import useRequest from "~/hooks/use-request";
import useSegment from "~/hooks/use-segment";
import { formatDeviceModel, getCurrentDeviceInfo } from "~/services/format";
import useUserStore from "~/stores/user";
import { formatUserModel } from "~/utils/user";
import DeviceAPI from "../device";
import { loginApple } from "./apple";
import { loginFacebook } from "./facebook";
import { loginGoogle } from "./google";
import { loginIFA } from "./ifa";
// TODO: Move auth module into a separate package.

interface IAuthSuccessParams {
  profile: IResponseProfile;
  token: string;
  tokenUpdateTime: number;
  tokenExpirationTime: number;
  refreshToken: string;
  devices: IResponseDevice[];
}

interface ISocialAuthPayload {
  grant_type: string;
  subject_token_type: string;
  subject_issuer: TAuthProvider;
  requested_token_type: string;
  subject_token: string;
  device: {
    id: string;
    name: string;
    os_name: string;
    os_version: string;
    app_version: string;
  };
  strategy: string;
  profile?: { display_name: string };
}

type providerResponse = IProviderSocialResponse | IProviderAppleResponse;

export function onAuthSuccess(params: IAuthSuccessParams) {
  const { profile, token, tokenExpirationTime, tokenUpdateTime, refreshToken, devices } = params;

  const userStore = useUserStore();
  const oneSignal = useOneSignal();
  const segment = useSegment();
  const user = formatUserModel(profile);

  userStore.user = user;
  userStore.token = token; // TODO: Handling the users who are currently logged in. Will be removed in the future.
  userStore.userTokenData = { token, tokenExpirationTime, tokenUpdateTime, refreshToken };
  userStore.devices = devices.map(formatDeviceModel);
  oneSignal.sendTag("user_id", user.id);
  segment.identify(user.id, user);
}

export function handleAuthResponse<T extends IResponseSSOLogin | IContentResponse<IResponseLogin>>(response: T, provider: TAuthProvider): void {
  const currentTime = Math.floor(Date.now() / 1000);

  let authData;
  let token;
  let expiresIn;
  let refreshToken;

  if ("content" in response) {
    authData = response.content;
    token = response.content.tokenData.access_token;
    expiresIn = response.content.tokenData.expires_in;
    refreshToken = response.content.tokenData.refresh_token;
  }
  else {
    authData = response.auth_data;
    token = response.token;
    expiresIn = response.expires_in;
    refreshToken = response.refresh_token;
  }

  if (authData.device_error) {
    const userStore = useUserStore();
    userStore.devices = authData.devices.map(formatDeviceModel);
    const error: Error & { code?: string; token?: string; provider?: TAuthProvider } = new Error(JSON.stringify(
      { message: "Device Limit Exceeded", code: "device_limit_exceeded" },
    ));

    error.code = "device_limit_exceeded";
    error.token = token;
    error.provider = provider;

    throw error;
  }

  const authParams = {
    profile: authData.profile,
    token,
    tokenExpirationTime: expiresIn,
    tokenUpdateTime: currentTime,
    refreshToken,
    devices: authData.devices,
  };

  onAuthSuccess(authParams);
}

const AuthAPI = {
  signup(payload: IRequestPayloadSignUp) {
    const userStore = useUserStore();
    const { request } = useRequest();
    const body = { ...payload, strategy: "refresh" };

    return request
      .post<IResponseSignUp>("/api/user/register", { body, setAuthorization: false })
      .then((response) => {
        const currentTime = Math.floor(Date.now() / 1000);

        userStore.user = formatUserModel(response.content.profile);
        userStore.userTokenData = {
          token: response.content.tokenData.access_token,
          tokenExpirationTime: response.content.tokenData.expires_in,
          tokenUpdateTime: currentTime,
          refreshToken: response.content.tokenData.refresh_token,
        };
      })
      .then(() => AuthAPI.signin(payload.email, payload.password));
  },

  signin(username: string, password: string): Promise<void> {
    const { request } = useRequest();
    const device = getCurrentDeviceInfo();
    const payload: IRequestPayloadLogin = {
      username,
      password,
      device_id: device.deviceId,
      device_name: device.deviceName,
      strategy: "refresh",
    };

    return request
      .post<IContentResponse<IResponseLogin>>("/api/user/login", { body: payload, setAuthorization: false })
      .then(response => handleAuthResponse(response, "credentials"));
  },

  async signinWithProvider(provider: TAuthProvider): Promise<void> {
    // eslint-disable-next-line no-console
    console.log(`Signing in with '${provider}' provider...`);
    let providerResponse: providerResponse | null = null;

    switch (provider) {
      case SOCIAL_PROVIDER.FACEBOOK:
        providerResponse = await loginFacebook();
        break;
      case SOCIAL_PROVIDER.GOOGLE:
        providerResponse = await loginGoogle();
        break;
      case SOCIAL_PROVIDER.APPLE:
        providerResponse = await loginApple();
        break;
      case SOCIAL_PROVIDER.IFA:
        providerResponse = await loginIFA();
        break;
      default:
        throw new Error(`"${provider}" auth provider not supported!`);
    }

    return AuthAPI.signSSO(providerResponse).then(response => handleAuthResponse(response, provider));
  },

  signSSO(provider: providerResponse): Promise<IResponseSSOLogin> {
    const config = useRuntimeConfig().public;
    const { request } = useRequest();
    const currentDevice = DeviceAPI.current();
    const token_type = `urn:ietf:params:oauth:token-type:${provider.token_type}`;
    let subject_token: string;

    if ("access_token" in provider)
      subject_token = provider.access_token;
    else
      subject_token = provider.id_token;

    const payload: ISocialAuthPayload = {
      grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
      subject_token_type: token_type,
      subject_issuer: provider.provider_name,
      requested_token_type: token_type,
      subject_token,
      device: {
        id: currentDevice.deviceId,
        name: currentDevice.deviceName,
        os_name: "",
        os_version: "",
        app_version: config.APP_VERSION,
      },
      strategy: "refresh",
    };

    if ("full_name" in provider)
      payload.profile = { display_name: provider.full_name.trim() };

    return request.post<IResponseSSOLogin>("/api/oauth2/token", { body: payload, setAuthorization: false });
  },

  logout(authToken: string) {
    const { request } = useRequest();

    return request
      .post<IResponseSignUp>("/api/user/logout", { withToken: authToken });
  },

  refreshAccessToken(): Promise<void> {
    const userStore = useUserStore();
    const error: Error & { code?: string } = new Error(JSON.stringify({ message: "Expired Session ", code: "expired_session_error" }));
    error.code = "refresh_token_failed";

    if (!userStore.userTokenData?.refreshToken)
      return Promise.reject(error);

    if (userStore.pendingAccessTokenRefresh === null) {
      const config = useRuntimeConfig().public;
      const { request } = useRequest();
      const currentDevice = DeviceAPI.current();

      const payload = {
        grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
        subject_token_type: "urn:ietf:params:oauth:token-type:refresh_token",
        subject_issuer: "pixellot",
        requested_token_type: "urn:ietf:params:oauth:token-type:access_token",
        subject_token: userStore.userTokenData.refreshToken,
        device: {
          id: currentDevice.deviceId,
          name: currentDevice.deviceName,
          os_name: "",
          os_version: "",
          app_version: config.APP_VERSION,
        },
        strategy: "refresh",
      };

      userStore.pendingAccessTokenRefresh = request
        .post<IResponseSSOLogin>("/api/oauth2/token", { body: payload, setAuthorization: false })
        .then((response) => {
          const currentTime = Math.floor(Date.now() / 1000);

          userStore.userTokenData = {
            token: response.token,
            tokenExpirationTime: response.expires_in,
            tokenUpdateTime: currentTime,
            refreshToken: response.refresh_token,
          };
        })
        .catch(() => Promise.reject(error))
        .finally(() => {
          userStore.pendingAccessTokenRefresh = null;
        });
    }

    return userStore.pendingAccessTokenRefresh;
  },
};

export default AuthAPI;
