import type { PxlRequestInterceptorContext } from "./index";
import useUserStore from "~/stores/user";

export const setAuthorizationInterceptor = async (ctx: PxlRequestInterceptorContext) => {
  const { fetchOptions, requestOptions } = ctx;
  if (requestOptions.withToken) {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "X-Authorization": `Bearer ${requestOptions.withToken}`,
    };
    return;
  }

  const accessToken = await getCurrentAccessToken(requestOptions.forceRefreshToken);

  if (!accessToken)
    return;

  fetchOptions.headers = {
    ...fetchOptions.headers,
    "X-Authorization": `Bearer ${accessToken}`,
  };
};

async function getCurrentAccessToken(forceRefreshToken = false) {
  const userStore = useUserStore();
  const token = userStore.userTokenData?.token || userStore.token;

  if (!token)
    return null;

  const isTokenExpired = checkAccessTokenExpiration(userStore.userTokenData?.tokenExpirationTime, userStore.userTokenData?.tokenUpdateTime);

  if (isTokenExpired || forceRefreshToken)
    await userStore.refreshAccessToken();

  return userStore.userTokenData?.token;
}

export function setBugsnagErrorInterceptor(fetchError: Response) {
  const bugsnag = useBugsnag();
  const isServerError = fetchError.status >= 500;

  if (!bugsnag || !isServerError)
    return;

  const bugsnagMetaData = {
    statusCode: fetchError.status,
    headers: fetchError.headers,
    statusText: fetchError.statusText,
    url: fetchError.url,
  };

  bugsnag.notify(fetchError, (event: any) => event.addMetadata("metadata", { bugsnagMetaData }));
}
