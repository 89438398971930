export const COMMERCE_CATEGORY = {
  FREE: "commerce_free_media",
  MEMBER: "commerce_members_media",
  PPV: "commerce_ppv_media",
  TRANSACTIONAL: "commerce_transactional_media",
  SUBSCRIPTION: "commerce_subscription_basic_media",
} as const;

export const COMMERCE_TYPE = {
  PPV: "ppv",
  SVOD: "svod",
  CVOD: "cvod",
} as const;

export const COMMERCE_TRANSACTION_STATUS = {
  APPROVED: "APPROVED",
  ERROR: "ERROR",
  PENDING: "PENDING",
} as const;

export const COMMERCE_TRANSACTION_TYPE = {
  REDEEM_ACCESS_CODE: "redeemAccessCode",
  CREDIT_CARD_PAY: "creditCardPay",
  CANCEL_SUBSCRIPTION: "cancelSubscription",
} as const;
