import { EVENT_MEDIA_TYPE } from "~/constants";
import useRequest from "~/hooks/use-request";
import Event from "./event.model";

const UserMediaEventsAPI = {
  load(options: IRequestPaginatedUserMediaOptions): Promise<IPaginatedResponse<IModelEvent>> {
    const { request } = useRequest();
    const defaultParams = {
      next: true,
      count: false,
      page: 0,
      size: 10,
    };
    const params = Object.assign({}, defaultParams, options);

    return request
      .post<IPaginatedResponse<IResponseEvent>>("/api/clip/user_list", { body: params })
      .then(response => Event.formatPaginated(response, Event.formatMediaEvent));
  },

  loadHighlights(options: IRequestPaginatedUserMediaOptions): Promise<IPaginatedResponse<IModelMediaEvent>> {
    const { request } = useRequest();

    const defaultParams = {
      next: true,
      count: false,
      page: 0,
      size: 10,
      type: [EVENT_MEDIA_TYPE.GAME],
    };
    const params = Object.assign({}, defaultParams, options);

    return request
      .post<IPaginatedResponse<IResponseEvent>>("/api/clip/list", { body: params })
      .then(response => Event.formatPaginated(response, Event.formatMediaEvent));
  },

  createUserClip(options: IRequestOptionsUserClip) {
    const { request } = useRequest();

    return request
      .post<IResponseUserMedia>("/api/clip/create", { body: options });
  },

  updateUserClip(mediaId: string, title: string): Promise<IContentResponse<IResponseEvent>> {
    const { request } = useRequest();

    return request
      .post<IContentResponse<IResponseEvent>>(`/api/clip/update/${mediaId}`, { body: { title } });
  },

  deleteUserClip(mediaId: string) {
    const { request } = useRequest();

    return request
      .post<IResponseUserMedia>(`/api/clip/delete/${mediaId}`);
  },
};

export default UserMediaEventsAPI;
