import { formatProviderResponse } from "~/utils/auth";

declare const google: Window & typeof globalThis & { accounts: any };

export interface IResponseGoogleAuth {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  authuser: string;
  hd: string;
  prompt: string;
}

export function initGoogleSdk() {
  const googleSignInAPI = document.createElement("script");

  googleSignInAPI.setAttribute("src", "https://accounts.google.com/gsi/client");
  document.head.appendChild(googleSignInAPI);
}

export async function loginGoogle(): Promise<IProviderSocialResponse> {
  const appConfig = useRuntimeConfig().public;
  const client_id = appConfig.APP_CUSTOMER.app_config.app_client_id_for_google;

  return new Promise((resolve, reject) => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id,
      scope: "openid email profile",
      callback: async (response: IResponseGoogleAuth) => {
        try {
          const providerResponse = formatProviderResponse(response.access_token, "google");
          resolve(providerResponse);
        }
        catch (error) {
          reject(error);
        }
      },
      error_callback: (error: string) => reject(error),
    });

    client.requestAccessToken();
  });
}
