export interface IDeviceInformation {
  deviceId: string;
  deviceName: string;
}

export const getCurrentDeviceInfo = (): IDeviceInformation => ({
  deviceId: navigator.userAgent,
  deviceName: navigator.userAgent,
});
export const formatDeviceModel = (deviceData: IResponseDevice): IModelDevice => {
  const currentDevice = getCurrentDeviceInfo();
  return {
    id: deviceData._id,
    addedAt: deviceData.added,
    updatedAt: deviceData.updated,
    deviceId: deviceData.deviceId,
    deviceName: deviceData.deviceName,
    deviceType: getDeviceType(deviceData.deviceId),
    isCurrent: currentDevice.deviceId === deviceData.deviceId,
    osName: deviceData.osName,
    osVersion: deviceData.osVersion,
  };
};

export function getDeviceType(device: string): TDeviceType {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(device))
    return "tablet";

  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(device))
    return "mobile";

  return "desktop";
}
