<script lang="ts" setup>
const modalState = inject(modalInjectionKey);
const { isOpen } = useModal();
</script>

<template>
  <component
    :is="modalState.component"
    v-if="modalState"
    v-bind="modalState.props"
    v-model="isOpen"
  />
</template>
