import type { Socket } from "socket.io-client";
import { getCurrentDeviceInfo } from "~/services/format";
import { SOCKET_EVENT } from "./index";

export interface useDeviceSocketOptions {
  onDeviceRemoved: () => void;
}

export default function useDeviceSocket(socket: Socket, options: useDeviceSocketOptions) {
  socket.on(SOCKET_EVENT.REMOVE_USER_DEVICE, onDeviceRemoved);

  function onDeviceRemoved(event: { deviceId: string }) {
    const currentDevice = getCurrentDeviceInfo();

    if (currentDevice.deviceId === event.deviceId)
      options.onDeviceRemoved();
  }
}
