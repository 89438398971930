export const checkAccessTokenExpiration = (expiration?: number, tokenUpdateTime?: number) => {
  if (!expiration || !tokenUpdateTime)
    return false;

  const currentTime = Math.floor(Date.now() / 1000);
  const expirationThreshold = expiration * 0.8; // 80% from expiration
  const tokenLifetime = currentTime - tokenUpdateTime;

  return tokenLifetime >= expirationThreshold;
};

export const decodeJWTPayloads = (token: string) => {
  return JSON.parse(atob(token.split(".")[1]));
};
