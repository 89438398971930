import { MOBILE_OS } from "~/constants/mobile-os";

export const getMobileOS = () => {
  const ua = navigator.userAgent;

  if (/android/i.test(ua))
    return MOBILE_OS.ANDROID;

  if (/iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream)
    return MOBILE_OS.IOS;

  return MOBILE_OS.OTHER;
};
