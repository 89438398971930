import { formatAppleProviderResponse } from "~/utils/auth";

declare const window: Window & typeof globalThis & { AppleID: any };

export interface IResponseAppleAuth {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };

  user?: {
    name: {
      firstName: string;
      lastName: string;
    };
    email: string;
  };
}

export function initAppleSdk() {
  const appleSignInAPI = document.createElement("script");

  appleSignInAPI.setAttribute("src", "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js");
  document.head.appendChild(appleSignInAPI);
}

export async function loginApple(): Promise<IProviderAppleResponse> {
  const appConfig = useRuntimeConfig().public;
  const appleID = window.AppleID;

  const clientId = appConfig.APP_CUSTOMER.app_config.app_client_id_for_apple;
  const redirectURI = window.location.origin;
  const scope = "name email";
  const state = "your_state";

  appleID.auth.init({
    clientId,
    redirectURI,
    scope,
    state,
    usePopup: true,
  });

  return new Promise((resolve, reject) => {
    appleID.auth.signIn()
      .then((response: IResponseAppleAuth) => {
        const profile = formatAppleProviderResponse(response.user, response.authorization.id_token);

        resolve(profile);
      })
      .catch((e: { error: string }) => {
        reject(new Error(e.error));
      });
  });
}
