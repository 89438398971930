import { formatProviderResponse } from "~/utils/auth";

declare const window: Window & typeof globalThis & { FB: any; fbAsyncInit: any };

export interface IResponseFBAuth {
  status: string;
  authResponse: {
    userID: string;
    expiresIn: number;
    accessToken: string;
    signedRequest: string;
    graphDomain: string;
    data_access_expiration_time: number;
  };
}

const version = "v15.0";

export function initFacebookSdk() {
  const appConfig = useRuntimeConfig().public;
  const facebookAppId = appConfig.APP_CUSTOMER.app_config.app_id_facebook;

  return new Promise((_resolve) => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      window.FB.init({ appId: facebookAppId, cookie: true, xfbml: true, version, autoLogAppEvents: true });
    };

    // load facebook sdk script
    (function (d, s: "script", id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id))
        return;
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));
  });
}

export function getFacebookLoginStatus(): Promise<IProviderSocialResponse> {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response: IResponseFBAuth) => {
      if (response.status !== "connected" || !response.authResponse)
        return reject(new Error("No user is currently logged in with Facebook"));

      resolve(formatProviderResponse(response.authResponse.accessToken, "facebook"));
    });
  });
}

export async function loginFacebook(): Promise<IProviderSocialResponse> {
  return new Promise((resolve, reject) => {
    window.FB.login((response: IResponseFBAuth) => {
      if (response.status !== "connected" || !response.authResponse)
        return reject(new Error("Facebook authentication failed"));

      resolve(formatProviderResponse(response.authResponse.accessToken, "facebook"));
    }, { scope: "public_profile,email" });
  });
}

export function logoutFacebook(): Promise<void> {
  return new Promise(resolve => window.FB.logout(resolve));
}
