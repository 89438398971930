import useRequest from "~/hooks/use-request";
import Event from "./event.model";

const EventStatsAPI = {
  getEventScoreStatsById(id: string): Promise<IEventStats> {
    const { request } = useRequest();

    return request
      .get<IContentResponse<IResponseEventStats>>("/api/event/stats", { query: { id, score: true, overview: true } })
      .then((res) => {
        const scores = Event.formatScoreStats(res.content?.vidswap?.["game-scores"]);
        const overview = Event.formatGameStats(res.content?.vidswap?.["game-overview"]);

        return { scores, overview };
      });
  },
};

export default EventStatsAPI;
