import { getFavoriteClubIdFromList } from "~/services/format";

export const formatUserModel = (userData: IResponseProfile): IModelUser => {
  const favoriteClubId = getFavoriteClubIdFromList(userData.clubList);

  return {
    id: userData.userId,
    email: userData.email,
    fullName: userData.displayName,
    phone: userData.phone,
    countryName: userData.countryName,
    canCreateGlobalTag: userData.globalTags,
    favoriteClubId,
    postalCode: userData.postalCode,
  };
};
