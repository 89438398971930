export const removeObjectFromArrayByKeyValue = (value: any, key: string, arr: any[]) => {
  const pIndex = arr.findIndex(obj => obj[key] === value);

  if (pIndex > -1)
    arr.splice(pIndex, 1);

  else
    console.error(`Failed to remove object by key '${key}', with value '${value}', from the given array`);
};

export const truncateArrayOfStringByValue = (arr: string[], value: number) => {
  if (arr.length > value)
    return `${arr.slice(1, value + 1).join(", ")}...`;

  return arr.join(", ");
};

// export const findByKey = <T>(value: any, key: string, arr: any[]): T | null => arr.find((obj) => obj[key] === value);

/**
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_groupby
 */
export const groupByKey = <T>(arr: T[], fk: (i: T) => string): Record<string, T[]> => {
  // eslint-disable-next-line no-sequences
  return arr.reduce((r, v, i, a, k = fk(v)) => ((r[k] || (r[k] = [])).push(v), r), {} as Record<string, T[]>);
};
