import Bowser from "bowser";
import Cookies from "js-cookie";
import { MOBILE_OS } from "~/constants/mobile-os";
import { getMobileOS } from "~/utils/mobile-os";

export default function useSmartBanner(options?: { minsHidden?: number }) {
  const appConfig = useRuntimeConfig().public;
  const parser = Bowser.getParser(window.navigator.userAgent);
  const isSmartBannerEnabled = Boolean(appConfig.APP_CUSTOMER.enable_smart_app_banner
    && (appConfig.APP_CUSTOMER.social.url_google_play_store || appConfig.APP_CUSTOMER.social.url_app_store));

  const showBanner = ref<boolean>(true);
  const minsHidden = options?.minsHidden || 10;
  const deepLink = appConfig.APP_CUSTOMER.portal_url;
  const storeGoogleUrl = appConfig.APP_CUSTOMER.social.url_google_play_store;
  const storeAppleUrl = appConfig.APP_CUSTOMER.social.url_app_store;
  const isMobile = computed(() => parser.getPlatformType() === "mobile");
  const isSafari = computed(() => parser.getBrowserName() === "Safari");
  const isVisible = computed(() => isSmartBannerEnabled && isMobile.value && showBanner.value && !isSafari.value);
  const mobileOS = getMobileOS();
  const storeURL = computed(() => {
    if (mobileOS === MOBILE_OS.IOS && storeAppleUrl)
      return `${deepLink}/?storeUrl=${storeAppleUrl}`;

    if (mobileOS === MOBILE_OS.ANDROID && storeGoogleUrl)
      return `${deepLink}/?storeUrl=${storeGoogleUrl}`;

    return deepLink;
  });
  const bannerClosedCookie = `${appConfig.APP_CUSTOMER.name_short}-smart-banner-closed`;

  if (Cookies.get(bannerClosedCookie) === "true")
    showBanner.value = false;

  const handleBannerClick = () => {
    window.location.href = storeURL.value;
  };

  return {
    isVisible,
    logo: appConfig.APP_CUSTOMER.appearance.logo_url,
    onClick: handleBannerClick,
    onClose: () => {
      showBanner.value = false;
      Cookies.set(bannerClosedCookie, "true", { expires: new Date(Number(new Date()) + minsHidden * 1000 * 60) });
    },
  };
}
