import { onDeviceLimitError } from "~/utils/device";

export interface IResponseIfaAuth {
  token_type: string;
  expires_in: number;
  access_token: string;
}

export const openPopup = <T>(url: string, title = "Login"): Promise<T> => {
  const windowArea = {
    width: Math.max(Math.floor(window.outerWidth * 0.30), 512),
    height: Math.max(Math.floor(window.outerHeight * 0.6), 730),
    left: Math.floor(window.screenX + ((window.outerWidth - Math.floor(window.outerWidth * 0.25)) / 2)),
    top: Math.floor(window.screenY + ((window.outerHeight - Math.floor(window.outerHeight * 0.7)) / 2)),
  };

  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
  width=${windowArea.width},height=${windowArea.height},
  left=${windowArea.left},top=${windowArea.top}`;

  const authWindow = window.open(url, title, windowOpts);

  return new Promise<T>((resolve, reject) => {
    if (!authWindow) {
      reject(new Error(JSON.stringify({ message: "Popup was closed", code: "popup_closed" })));
      return;
    }

    const checkInterval = setInterval(() => {
      if (!authWindow || authWindow.closed) {
        clearInterval(checkInterval);
        reject(new Error(JSON.stringify({ message: "Popup was closed", code: "popup_closed" })));
      }
    }, 500);

    const messageEventListener = async (event: MessageEvent) => {
      if (event.origin !== window.location.origin)
        return;

      if (event.data.error) {
        const errorData = JSON.parse(event.data.error);
        let error: Error = new Error(JSON.stringify({ message: "Login Failed", code: "login_failed" }));

        if (errorData.code === "device_limit_exceeded") {
          error = new Error(JSON.stringify({ message: "Device Limit Exceeded", code: "device_limit_exceeded" }));
          onDeviceLimitError(errorData.token, errorData.provider);
        }

        authWindow.close();
        reject(error);
      }

      if (event.data.access_token) {
        const providerResponse = formatProviderResponse(event.data.access_token, "ifa");

        resolve(providerResponse as T);

        window.removeEventListener("message", messageEventListener);
        clearInterval(checkInterval);
        authWindow.close();
      }

      if (event.data.success) {
        resolve(event.data.success);

        window.removeEventListener("message", messageEventListener);
        clearInterval(checkInterval);
        authWindow.close();
      }
    };

    window.addEventListener("message", messageEventListener, false);
  });
};

export function loginIFA(): Promise<IProviderSocialResponse> {
  const appConfig = useRuntimeConfig().public;
  const clientId = appConfig.APP_CUSTOMER.app_config.app_client_id_for_ifa;
  const ifaOauthBaseURL = appConfig.APP_CUSTOMER.app_config.ifa_oauth_url;
  const redirectURI = `${window.location.origin}/auth/oauth2/callback/ifa/`;
  const url = `${ifaOauthBaseURL}/?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&scope=profile`;
  const authPopup = openPopup<IProviderSocialResponse>(url, "IFA Login");

  return authPopup;
}

export function fetchIFAAccessToken(code: string): Promise<IResponseIfaAuth> {
  const appConfig = useRuntimeConfig().public;
  const client_id = appConfig.APP_CUSTOMER.app_config.app_client_id_for_ifa;
  const ifaBaseUrl = appConfig.APP_CUSTOMER.app_config.ifa_api_base_url;
  const redirect_uri = `${window.location.origin}/auth/oauth2/callback/ifa/`;

  return fetch(`${ifaBaseUrl}/endpoint/oauth2/access_token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "authorization_code",
      client_id,
      code,
      redirect_uri,
    }),
  })
    .then(response => response.json())
    .then((data) => {
      if (data.error)
        throw new Error(data.error_description);

      return data;
    });
}
