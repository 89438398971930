import type { Socket } from "socket.io-client";
import useUserStore from "~/stores/user";
import { SOCKET_EVENT, SOCKET_IO_EVENT } from "./index";

export default function useUserSocket(socket: Socket) {
  const userStore = useUserStore();

  socket.on(SOCKET_IO_EVENT.CONNECT, onSocketConnect);

  watch(
    () => userStore.user,
    (user, oldUser) => {
      if (user)
        joinUser(user);
      else if (oldUser)
        leaveUser(oldUser);
    },
  );

  function onSocketConnect() {
    userStore.user && joinUser(userStore.user);
  }

  function joinUser(user: IModelUser) {
    const payload = { userId: user.id };
    // TODO: Remove logging in future
    // eslint-disable-next-line no-console
    const onMessageResponse = (response: any) => console.log("user joined socket room", JSON.parse(response));

    socket.emit(SOCKET_EVENT.JOIN_USER_ROOM, payload, onMessageResponse);
  }

  function leaveUser(user: IModelUser) {
    const payload = { userId: user.id };
    // eslint-disable-next-line no-console
    const onMessageResponse = (response: any) => console.log("user left socket room", JSON.parse(response));

    socket.emit(SOCKET_EVENT.LEAVE_USER_ROOM, payload, onMessageResponse);
  }
}
