import type { RouterConfig } from "@nuxt/schema";

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior(to, _, savedPosition) {
    const nuxtApp = useNuxtApp();

    // If history back
    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce("page:finish", () => {
          setTimeout(() => resolve(savedPosition), 50);
        });
      });
    }
    // Scroll to heading on click
    if (to.hash) {
      setTimeout(() => {
        try {
          const heading: HTMLElement | null = document.querySelector(to.hash);

          if (heading)
            window.scrollTo({ top: heading.offsetTop, behavior: "smooth" });
        }
        catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });

      return;
    }

    // Scroll to top of window
    return { top: 0 };
  },
};
